import success from "../../assets/illustrations/success-lottie.json";
import React, { useEffect, useContext } from "react";
import Lottie from "lottie-react";
import { useState } from "react";
import TintBackground from "../common/TintBackground";
import GlobalContext from "../../context/GlobalContext";

const Success = ({ opened, lockScroll, disabled }) => {
  const [showed, setShowed] = useState(null);

  const [tintBackground, setTintBackground] = useState(false);

  const context = useContext(GlobalContext);

  useEffect(() => {
    context.setTint(opened)
    setShowed(opened);
    const blur = context.tint ? "blur(4px)" : "";
    context.setBlur(blur);
  }, [opened]);
  return (
    showed && (
      <>
        <div
          aria-disabled="true"
          style={{
            position: "fixed",
            zIndex: 999999,
            height: "100vh",
            width: "100vw",
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              WebkitAlignItems: "center",
              WebkitJustifyContent: "center",
              maxWidth: "100vw",
              maxHeight: "100vh",
            }}
          >
            <Lottie
              animationData={success}
              async={true}
              style={{ height: "55vh", width: "70vw" }}
            />
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Ubuntu",
              fontWeight: "bold",
              color: "white",
            }}
          >
            Успешно барање!
          </p>
        </div>
      </>
    )
  );
};

export default Success;
