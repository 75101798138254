import React,{ useContext } from "react";
import { Link } from "react-router-dom";
import { Title } from "./../styled/Typography";
import Card from "./../styled/Card";
import PropTypes from "prop-types";
import CardImage from "../styled/CardImage";
import GlobalContext from "../../context/GlobalContext";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const BasicCard = ({ to, image, title }) => {
  const context = useContext(GlobalContext);
  return (
    <LazyLoadComponent>
    <Link to={to}>
      <Card style={{ background: context.configuration.uiConfig.secondary, filter: context.blur }}>
        {image &&
      <CardImage src={image} />
      }  
        <div style={{ padding: 18 }}>
          <Title style={{color: context.configuration.uiConfig.primaryFont }}>{title}</Title>
        </div>
      </Card>
    </Link>
    </LazyLoadComponent>

  );
};

BasicCard.propTypes = {
  to: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default BasicCard;
