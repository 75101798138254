import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CategoryButton from "../styled/CategoryButton";

const CategoryBtn = ({ to, title, selected }) => {
  return (
    <Link to={to} replace={true} style={{ textDecoration: "none" }}>
      <CategoryButton selected={selected}>{title}</CategoryButton>
    </Link>
  );
};

export default CategoryBtn;

CategoryBtn.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool
};
