import styled from "styled-components";

const Error = styled.div`
  margin: auto;
  width: 50%;
  padding: 10px;
  margin-top: 50vh;
  background-color: lightgray;
  text-align: center;
  border-radius: 15px;
`;

export default Error;
