import { get, post} from "./apiInterfaces";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT

const getMenu = (restaurantId, language) => {
  return get(BASE_URL, "/restaurant/menu-v2/" + restaurantId + "/" + language);
};

const getItems = (categories, language) => {
  return post(BASE_URL, "/restaurant/items-menu-v2/" + language, {categories})
}
const getRestaurantDetails = id => {
  return get(BASE_URL, "/restaurant/config/" + id);
};

const searchTags = (id, tags) => {
  return post(BASE_URL, "/restaurant/get/menuByTags", {restaurantId: id, language: "en", tags: tags})
}

const createClientLog = ({ restaurantId, fullName, email, phoneNumber, tableNumber }) => {
  return post(BASE_URL, "/client-log/", { restaurantId, fullName, email, phoneNumber, tableNumber });
};

export { getMenu, getRestaurantDetails, searchTags, getItems, createClientLog };