import React, { useState, useEffect, useContext } from "react";
import GlobalContext from "../../context/GlobalContext";

const TintBackground = ({ clickFab, disabled }) => {
  const [tint, setTintScreen] = useState(null);

  const context = useContext(GlobalContext);

  useEffect(() => {
    setTintScreen(context.tint);
    document.body.style.overflow = context.tint ? "hidden" : "scroll";
    document.body.style.pointerEvents = disabled ? "none" : "auto";
    const blur = context.tint ? "blur(4px)" : "";
    context.setBlur(blur);
  }, [context.tint, context.success]);

  const clickTint = () => {
    clickFab();
  };

  return (
    tint && (
      <div
        aria-disabled={`${disabled}`}
        onClick={() => clickTint()}
        style={{
          width: "100vw",
          height: "100vh",
          background: "rgba(0,0,0, 0.8)",
          position: "fixed",
          zIndex: 999,
          overflow: "none",
        }}
      ></div>
    )
  );
};

export default TintBackground;
