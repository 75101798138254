import React, { useReducer } from "react";
import mockData from "./../utils/mockData";
import GlobalContext from "./GlobalContext";
import reducer from "./Reducer";
import {
  CHANGE_LANGUAGE,
  SELECT_CATEGORY,
  SET_MENU,
  SET_LOADING,
  SET_CONFIGURATION,
  SET_ON_LOAD_DIALOG,
  SET_BACKGROUND_BLUR_ON_OPEN_FAB,
  SET_TINT,
  SET_SUCCESS,
} from "./Actions";

export const OnLoadDialogType  = {
  POP_UP_AD: "POP_UP_AD",
  CLIENT_LOG:  "CLIENT_LOG"
}
const initialState = {
  selectedCategoryId: null,
  selectedLanguage: '',
  languages: mockData.languages,
  menu: null,
  loading: true,
  configuration: null,
  blur: "",
  tint: false,
  success: false,
  onLoadDialogs: [{ dialog_type: OnLoadDialogType.POP_UP_AD, show: true, order: 2 }, { dialog_type: OnLoadDialogType.CLIENT_LOG, show: true, order: 1 }]
};

const GlobalState = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    menu: state.menu,
    selectedLanguage: state.selectedLanguage,
    languages: state.languages,
    selectedCategoryId: state.selectedCategoryId,
    loading: state.loading,
    configuration: state.configuration,
    onLoadDialogs: state.onLoadDialogs,
    blur: state.blur,
    tint: state.tint,
    success: state.success,
    setBlur: (blur) => {
      dispatch({type: SET_BACKGROUND_BLUR_ON_OPEN_FAB, blur: blur})
    },
    setTint: (tint) => {
      dispatch({type: SET_TINT, tint: tint})
    },
    setSuccess: (success) => {
      dispatch({type: SET_SUCCESS, success: success})
    },
    setOnLoadDialogs: (dialog_type, show) => {
      dispatch({ type: SET_ON_LOAD_DIALOG, dialog_type: dialog_type, show: show })
    },
    changeLanguage: language => {
      dispatch({ type: CHANGE_LANGUAGE, language: language })
    },
    selectCategory: category =>
      dispatch({ type: SELECT_CATEGORY, category: category }),
    setMenu: menu => {
      dispatch({ type: SET_MENU, menu: menu })
    },
    setLoading: value => {
      dispatch({ type: SET_LOADING, loading: value })
    },
    setConfiguration: configuration => {
      dispatch({ type: SET_CONFIGURATION, configuration: configuration })
    }
  };

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
