import { CHANGE_LANGUAGE, SELECT_CATEGORY, SET_MENU, SET_LOADING, SET_CONFIGURATION, SET_ON_LOAD_DIALOG, SET_BACKGROUND_BLUR_ON_OPEN_FAB, SET_TINT, SET_SUCCESS } from "./Actions";

const reducer = (state, action) => {
  switch (action.type) {
    case SELECT_CATEGORY:
      return { ...state, selectedCategoryId: action.category };
    case CHANGE_LANGUAGE:
      return { ...state, selectedLanguage: action.language };
    case SET_MENU:
      return { ...state, menu: { ...state.menu, ...action.menu } };
    case SET_LOADING:
      return { ...state, loading: action.loading };
    case SET_CONFIGURATION:
      return { ...state, configuration: action.configuration };
    case SET_ON_LOAD_DIALOG:
      // Update status of dialog
      const latestOnLoadDialogs = state.onLoadDialogs.map(item => {
        if(item.dialog_type === action.dialog_type){
          item.show = action.show;
        }
        return item;
      });
      return { ...state, onLoadDialogs: latestOnLoadDialogs };
    case SET_BACKGROUND_BLUR_ON_OPEN_FAB: 
        return {...state, blur: action.blur}
    case SET_TINT: 
      return {...state, tint: action.tint}
    case SET_SUCCESS:
      return {...state, success: action.success}
    default:
      return state;
  }
};

export default reducer;
