import axios from "axios";

const get = (baseURL, url, data) => {
  return axios({
    method: "get",
    baseURL: baseURL,
    url: url,
    data: data
  })
    .then(res => {
      return res;
    })
    .catch(e => console.log("error: ", e));
};

const post = (baseURL, url, data) => {
  return axios({
    method: "post",
    baseURL: baseURL,
    url: url,
    data: data
  })
    .then(res => {
      return res;
    })
    .catch(e => console.log("error: ", e));
};



export { get, post};
