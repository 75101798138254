import styled from 'styled-components';
// width: ${props=> props.theme.cardSize};

const Card = styled.div`
display: inline-table;
width: 80%;
text-align: left;
border-radius: 10px;
box-shadow: 0px 5px 15px rgb(95, 95, 95);
margin: 20px;`


export default Card;