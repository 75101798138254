import React, { createRef, useContext, useEffect, useState } from "react";
import Routes from "./routes";
import { getMenu, getRestaurantDetails, getItems } from "./utils/APIs";
import GlobalContext from "./context/GlobalContext";
import Loading from "./components/common/Loading";
import Error from "./components/styled/Error";
import "./animations.scss";
import "./global.scss";
import i18n from "i18next";
import ReactGa from "react-ga";
import Iframe from "react-iframe";
import FabRenderer from "../src/components/common/FabRenderer";
import {setUpWebSocket} from './utils/WebSocket.js';

let waiter = require("./assets/illustrations/waiter-success-animation.svg");
let receipt = require("./assets/illustrations/receipt-success-animation.svg");
let water = require("./assets/illustrations/water-success-animation.svg");

const App = () => {
  const context = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [htmlShow, setHtmlShow] = useState(false);

  const pathArray = window.location.pathname.split("/");
  const restaurantId = pathArray[1];
  let connectionFails = 0;

  const getData = async (lang) => {
    console.log("First load", restaurantId === "");
    if (restaurantId === "") {
      ReactGa.pageview("/");
      // setError("Please enter a restaurant URL");
      setHtmlShow(true);
      setLoading(false);
    } else {
      ReactGa.pageview(window.location.pathname + window.location.search);
      let restaurantData;
      let language;
      let menuData;
      try {
        restaurantData = await getRestaurantDetails(restaurantId);
        language = lang || restaurantData.data.languages[0].id;
        menuData = await getMenu(restaurantId, language);
      } catch (error) {
        connectionFails++;
        if (connectionFails < 3) {
          getData(lang);
          return;
        }
        setError("Something went wrong. Please check if the URL is correct.");
      }

      Promise.all([menuData, restaurantData])
        .then(([menuData, restaurantData]) => {
          if (menuData === undefined || restaurantData === undefined)
            setError(
              "Something went wrong. Please check if the URL is correct."
            );
          else {
            let table = window.location.search;
            context.setConfiguration({ ...restaurantData.data, fab: fab, table });
            context.setMenu(menuData.data);

            // set body color to secondary
            document.body.style.backgroundColor =
              restaurantData.data.uiConfig.backgroundColor;
            //   tintScreen && (
            // document.body.style.background = "rgba(1, 1, 1, 0.7)",
            // document.body.style.backgroundColor =
            //   restaurantData.data.uiConfig.backgroundColor )
          }
          setLoading(false);
          const promises = Object.values(menuData.data).map(async (menu) => {
            const categories = await getItems(menu.categories, language);
            menu.categories = categories?.data;
            return menu;
          });
          Promise.all(promises).then((fullMenu) => {
            const objectReturn = {};
            fullMenu.map((m) => {
              {
                objectReturn[m.type] = m;
              }
            });
            console.log("full", objectReturn);
            context.setMenu(objectReturn);
            localStorage.removeItem("new-lang");
          });
        })
        .catch(() => {
          setError("Restaurant does not exist or it's blocked");
        });
    }
  };

  useEffect(() => {
    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    const newLang = localStorage.getItem("new-lang");
    if (newLang) {
      context.changeLanguage(newLang);
      i18n.changeLanguage(newLang);
    }
    getData(newLang);
  }, []);

  const fab = {
    action: true,

    actions: [
      {
        icon: waiter,
        text: "Викни Келнер",
        actionName: "Келнер",
      },
      {
        icon: receipt,
        text: "Викни Сметка",
        actionName: "Сметка",
        paymentMethod: ["Кеш", "Картичка"]
      },
      {
        icon: water,
        text: "Викни Вода",
        actionName: "Вода",
      },
    ],
    table: null
  };

  return (
    <div>
      {loading && !htmlShow ? (
        <Loading />
      ) : (
        <div>
          {htmlShow && (
            <Iframe
              url="https://www.menu-pal.com/"
              width="100vw"
              height="100vh"
              id="iframeId"
              className="myClassname"
              display="initial"
              position="relative"
            />
          )}
          {error && !htmlShow ? (
            <Error>{error}</Error>
          ) : (
            <div onLoad={setUpWebSocket}>
              {!htmlShow && (
                <>
                  <div>
                    <Routes />
                  </div>
                </>
              )}{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
