import styled from "styled-components";

export const Title = styled.p`
  font-size: 38px;
  font-size: 3.5vh;
  font-weight: bold;
  color: black;
  margin: 0px;
  text-align: ${props=> props.align};
`;

export const TitleSM = styled.p`
  font-size: 38px;
  font-size: 2.5vh;
  font-weight: bold;
  margin-top: 0px;
  color: black;
  text-align: ${props=> props.align};
`;


export const Subtitle = styled.p`
  font-size: 35px;
  font-size: 2vh;
  margin: 10px auto 5px auto;
  text-align: ${props=> props.align};
`;

export const Paragraph = styled.p`
  font-size: 25px;
  font-size: 2.8vw;
  margin: 10px auto 5px auto;
  text-align: ${props=> props.align};
`;

export const MainTitle = styled.p`
font-size: 40px;
font-size: 4.5vh;
font-weight: bold;
margin: 0px;
color: ${props=> props.theme.primaryFont};
text-align: ${props=> props.align};
`;
