import React, { useContext, useEffect, useState } from "react";
import SearchBar from "../common/SearchBar";
import TagList from "./TagList";
import { useParams, useLocation } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import { searchTags } from "../../utils/APIs";
import MealCard from "./MealCard";

const Search = (props) => {
  const { tag } = useParams();
  const [items, setItems] = useState([]);
  const location = useLocation();
  const context = useContext(GlobalContext);

  const search = () => {
    searchTags(context.configuration.restaurantDetails.id, [tag]).then(res =>
      setItems(res.data)
    );
  };

  useEffect(() => search(), [location]);

  return (
    <div style={{ filter: context.blur }}>
      <SearchBar />
      <TagList tags={context.configuration.restaurantDetails.tags || []} />

      <div className="scroll-content">
        {items.map(m => (
          <MealCard key={m.id} meal={m} />
        ))}
      </div>
    </div>
  );
};

export default Search;
