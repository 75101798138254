import styled from "styled-components";
import React, { useContext } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const CardImage = ({src}) => (
  <div>
    <LazyLoadImage
      // effect="blur"
      style={{minHeight : "169px"}}
      placeholderSrc="https://camo.githubusercontent.com/0cbb62ec60fd23643b211d7ed0bb5ade6964f0da12124b95a0a3e7db096768c3/68747470733a2f2f7261772e6769746875622e636f6d2f74756c696f732f706c616365686f6c6465725f7368696d6d65722f6d61737465722f73616d706c652e676966"
      // placeholderSrc={src}
      src={src} // use normal <img> attributes as props
      width="100%" />
  </div>
);
// const CardImage = styled.img`
//   src: ${props=> props.image};
//   width: 100%;
// `;

export default CardImage;
