import React, { useState, useEffect, useContext } from "react";
import { Dialog } from "@material-ui/core";
import GlobalContext from "../../context/GlobalContext";
import { OnLoadDialogType } from "../../context/GlobalState";
import Icon from "@material-ui/core/Icon";

const closeBtn = {
    position: "absolute",
    right: 6,
    top: 6,
    fontSize: 14,
    border: "2px solid black",
    borderRadius: "100%",
    padding: 2,
    textAlign: "center",
    color: "black",
    backgroundColor: "lightgray",
    cursor: "pointer"
}

const PopUpAdDialog = ({ popUpAd  }) => {
    const context = useContext(GlobalContext);
    const [onLoadDialogTurn, setOnLoadDialogTurn] = useState(false);

    useEffect(() => {
        const dialogConfig = context.onLoadDialogs.sort((a, b) => a.order - b.order);
        for (const config of dialogConfig) {
            if (config.show == true && config.dialog_type !== OnLoadDialogType.POP_UP_AD) {
                setOnLoadDialogTurn(false);
                break;
            } else if (config.show == true && config.dialog_type === OnLoadDialogType.POP_UP_AD) {
                // My turn -> Initiate OPEN dialog flow
                setOnLoadDialogTurn(true);
                handleOpen();
                break;
            }
        }
    }, [context.onLoadDialogs]);

    const handleClose = () => {
        // Display turn ended -> Dont display again
        setOnLoadDialogTurn(false);
        // Remove precedence from Global context
        context.setOnLoadDialogs(OnLoadDialogType.POP_UP_AD, false);
    };

    const handleOpen = () => {
        if (popUpAd.active) {
            if (popUpAd.duration) {
                setTimeout(() => {
                    handleClose();
                }, popUpAd.duration * 1000);
            }
        } else {
            // Display turn ended -> Dont display again
            context.setOnLoadDialogs(OnLoadDialogType.POP_UP_AD, false);
            // Remove precedence from Global context
            setOnLoadDialogTurn(false);
        }
    };

    return (
        <Dialog open={onLoadDialogTurn} aria-labelledby="form-dialog-title">
            <Icon onClick={handleClose} className="fa fa-close" style={closeBtn} />
            <img src={popUpAd.imageUrl} style={{ width: "100%" }} alt="" />
        </Dialog>
    )
}

export default PopUpAdDialog;

PopUpAdDialog.defaultProps = {
    popUpAd: {
        active: 0,
        duration: 0,
        imageUrl: "",
        id: "",
        restaurantId: ""
    } 
};
