import React, { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import i18n from "i18next";

const LangaugeChooser = () => {
  const context = useContext(GlobalContext);

  const changeLanguage = event => {
    
    getDataNewLanguage(event.target.value);
  };

  const getDataNewLanguage = (lang) => {
    localStorage.setItem("new-lang",lang)
    window.location.replace(window.location.protocol + '//' + window.location.host + "/" + window.location.pathname.split("/")[1] + (localStorage.getItem("call") || ""));
  }
  return (

    <select
      name="language"
      value={context.selectedLanguage}
      onChange={changeLanguage}
      style={{border: 'none', marginRight: 10, backgroundColor: 'transparent', fontSize: 30, textTransform: 'uppercase', color: context.configuration.uiConfig.headerIconsColor, appearance: "menulist", filter: context.blur }}
    >
      {context.configuration.languages.map(language => (
        language.isEnabled && <option key={language.id} value={language.id}>
          {language.flagEmoji}
          </option>
      ))}
    </select>
    
  );
};

export default LangaugeChooser;
