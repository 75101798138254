import React, { useRef, useState, useContext, useEffect } from "react";
import { Typography } from "@material-ui/core";
import Success from "./Success";
import TintBackground from "./TintBackground";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { makeStyles } from "@material-ui/core";
import SocialMediaLinks from "./SocialMediaLinks";
import "react-lazy-load-image-component/src/effects/blur.css";
import GlobalContext from "../../context/GlobalContext";
import ReceiptDialog from "./ReceiptDialog";
import { makeCall } from "../../utils/WebSocket.js";
import { FlashOnRounded } from "@material-ui/icons";

let fabXIcon = require("../../assets/illustrations/fabplus.svg");
let moreFabIcon = require("../../assets/illustrations/More Circle.svg");

// style is just for mobile version

//fab style
const style = {
  left: "0px",
  bottom: "24px",
};

const useStyles = makeStyles((theme) => ({
  actionImage: {
    height: 60,
  },
  fabIcon: {
    padding: "2vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignItems: "center",
  },
}));

const FabRenderer = () => {
  const [onCall, setOnCall] = useState(false);
  const [fabClicked, setFabClicked] = useState(false);
  // const [optionSelected, setOptionSelected] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [disabled, setFabDisabled] = useState(false);
  const [click, setClickFab] = useState(true);

  const context = useContext(GlobalContext);

  // actions come from global context from configuration.fab object.

  let actions = context.configuration?.fab?.actions;
  let tableContext = context.configuration.table;

  if (!tableContext.length) {
    actions = [];
  }

  const fabRef = useRef(null);

  const fabOutSideRef = useRef(null);

  const classes = useStyles();

  let table = window.location.search || context.configuration.table;
  localStorage.setItem("call", table);

  const clickFab = () => {
    fabRef.current.click();
    setClickFab(true);
  };

  const openFab = () => {
    fabOutSideRef.current.click();
    setClickFab(false);
  };

  useEffect(() => {
    if (fabClicked)
      context.setTint(fabClicked)
    else context.setTint(false)
  }, [fabClicked])

  const showFabIcon = () => {
    if (!fabClicked) {
      return (
        <div ref={fabOutSideRef} className={classes.fabIcon}>
          <img src={moreFabIcon} />
        </div>
      );
    } else {
      return (
        <div ref={fabRef} className={classes.fabIcon}>
          <img src={fabXIcon} />
        </div>
      );
    }
  };

  return (
    <>
      <div
        style={{
          pointerEvents: (context.success && "none" || onCall && "none")
        }}
      >
        <Success opened={onCall} disabled={disabled} />
        <TintBackground
          clickFab={clickFab}
          disabled={disabled}
        />
        <SocialMediaLinks clickFab={clickFab} visible={fabClicked} />
        <div
          onClick={() => {
            setFabClicked(!fabClicked);
            click ? openFab() : clickFab();
          }}
        >
          <Fab
            style={style}
            icon={showFabIcon()}
            event={"click"}
            alwaysShowTitle={true}
          >
            {actions.length > 0
              ? actions.map((e, index) => {
                  return (
                    <Action
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                        padding: 0,
                        color: "white",
                        WebkitAlignItems: "center",
                        WebkitJustifyContent: "space-between",
                        WebkitFlexDirection: "row",
                        flexGrow: 1,
                        WebkitFlexGrow: 1,
                      }}
                      key={index}
                      onClick={() => {
                        if (!e.actionName.includes("Сметка")) {
                          context.setTint(null)
                          makeCall(e?.actionName);
                          setFabDisabled(true);
                          setOnCall(true);
                          setTimeout(() => {
                            setOnCall(false);
                            setFabClicked(false);
                          }, 1250);
                          setFabDisabled(false);
                        } else {
                          setOpenDialog(!openDialog);
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          WebkitAlignItems: "center",
                          WebkitJustifyContent: "space-between",
                          WebkitFlexDirection: "row",
                        }}
                      >
                        <Action
                          style={{
                            display: "flex",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            backgroundColor: "white",
                            textAlign: "right",
                            justifyContent: "center",
                            alignItems: "center",
                            WebkitAlignItems: "center",
                            WebkitJustifyContent: "center",
                          }}
                          children={
                            <img
                              style={{
                                height: 30,
                                width: 30,
                              }}
                              src={e?.icon}
                            />
                          }
                        ></Action>
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        <span>
                          <Typography>{e?.text}</Typography>
                        </span>
                      </div>
                    </Action>
                  );
                })
              : null}
          </Fab>
        </div>
      </div>
      <ReceiptDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
};

export default FabRenderer;
