import { createContext } from "react";

const values = {
    loading: null,
    menu: null,
    selectedCategoryId: null,
    languages: null,
    selectedLanguage: null,
    configuration: null,
    onLoadDialogs: [],
    blur: "",
    tint: false,
    success: false,
    setOnLoadDialogs: (dialog_type, show) => {},
    selectCategory: category => {},
    changeLanguage: language => {},
    setMenu: menu => {},
    setLoading: value => {},
    setConfiguration: configuration => {},
    setBlur: value => {},
    setTint: tint => {},
    setSuccess: success => {}
  }

export default createContext(values);
