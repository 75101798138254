import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import Success from "../common/Success";
import GlobalContext from "../../context/GlobalContext";
import MoneyIcon from "@material-ui/icons/Money";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { makeCall } from "../../utils/WebSocket";
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const ReceiptDialog = (props) => {
  const classes = useStyles();
  const { open, setOpenDialog, } = props;

  const context = useContext(GlobalContext);

  const receipt = context.configuration?.fab?.actions[1]?.actionName;

  const paymentMethod = context.configuration?.fab?.actions[1]?.paymentMethod;

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const blur = open ? "blur(4px)" : "";
    context.setBlur(blur);
    success ? context.setSuccess(success) : context.setSuccess(open);
  }, [open, success])

  const handleClose = () => {
    setOpenDialog(!open);
  };

  const handleChoice = (value) => {
    handleClose();
    makeCall(receipt + " " + value);
    setSuccess(!success);
    setTimeout(() => {
      setSuccess(false);
    }, 1300);
  };

  return (
    <div>
      <Success opened={success} />
      <Dialog
        onClose={handleClose}
        open={open}
        style={{
          transition: open && "none",
          background: open && "rgba(0,0,0, 0.62)",
        }}
      >
        <DialogTitle>Изберете начин на плаќање</DialogTitle>
        <List>
          {paymentMethod.map((value, index) => (
            <ListItem button onClick={() => handleChoice(value)} key={index}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  {value === "Кеш" ? <MoneyIcon /> : <CreditCardIcon />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={value} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </div>
  );
};

export default ReceiptDialog;
