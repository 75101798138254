import styled from "styled-components";

const Navbar = styled.div`
  /* Adapt the colors based on primary prop */

  width: 100vw;
  display: flex;
  z-index: 10;
  justify-content: space-between;
  background-color: ${(props) => props.theme.primary};
  position: fixed;
  top: 0;
`;

export default Navbar;
