import React, { useContext, useEffect } from "react";
import Navbar from "../styled/Navbar";
import LanguageChooser from "./LanguageChooser";
import GlobalContext from "../../context/GlobalContext";
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBack from '@material-ui/icons/ArrowBack';
import GlobalState from "../../context/GlobalState";


const NavbarComponent = (props) => {
  const context = useContext(GlobalContext);
  const history = useHistory();

  const goHome = () => history.push("/" + context.configuration.restaurantDetails.username + "/" + (localStorage.getItem("call") || ""));

  const goBack = () => history.goBack();

  return (
    <>
      <Navbar>
        <div style={{ filter: context.blur }}>
          <IconButton onClick={goBack}>
            <ArrowBack fontSize="large" style={{ color: context.configuration.uiConfig.headerIconsColor }} />
          </IconButton>
          <IconButton onClick={goHome}>
            <HomeIcon fontSize="large" style={{ color: context.configuration.uiConfig.headerIconsColor }} />
          </IconButton>
        </div>
        <LanguageChooser />
      </Navbar>
      <div style={{ paddingTop: 59 }}>
        {props.children}
      </div>
    </>
  );
};

export default NavbarComponent;
