import React, { useEffect,useContext } from "react";
import Image from "./../styled/Image";
import CardImage from "../styled/CardImage";
import { MainTitle, TitleSM, Subtitle } from "../styled/Typography";
import { useTranslation } from "react-i18next";
import { MdArrowBack } from "react-icons/md";
import ReactPlayer from "react-player";
import TagList from "./TagList";
import "./Meal.scss";
import GlobalContext from "../../context/GlobalContext";

const Meal = (props) => {
  const meal = props.location.state.meal;
  const { t } = useTranslation();
  const context = useContext(GlobalContext);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="meal" style={{ filter: context.blur }}>
      {meal && (
        <div>
          {meal.image && <CardImage src={meal.image} />}

          <div className="meal-details" style={{background : context.configuration.uiConfig.mealDetailsBackgroundColor, minHeight: "90vh"}}>
            <MainTitle style={{color: context.configuration.uiConfig.primaryFont}}>{meal.name}</MainTitle>
            <TitleSM style={{color: context.configuration.uiConfig.primaryFont}}>{meal.price}</TitleSM>
            <Subtitle style={{ whiteSpace: "pre-wrap",color: context.configuration.uiConfig.secondaryFont  }}>{meal.description}</Subtitle>

            {meal.ingredients && meal.ingredients.length > 0 && (
              <>
                <TitleSM style={{color: context.configuration.uiConfig.primaryFont}}>{t("ingredients")}</TitleSM>
                <ul style={{color: context.configuration.uiConfig.primaryFont}}>
                  {meal.ingredients.map((i) => (
                    <li key={i.id}>{i.name}</li>
                  ))}
                </ul>
              </>
            )}

            {meal.allergies && meal.allergies.length > 0 && (
              <div>
                <TitleSM style={{color: context.configuration.uiConfig.primaryFont}}>{t("allergens")}</TitleSM>
                <div style={{ display: "flex", overflowX: "scroll", justifyContent: "flex-start" }}>
                  {meal.allergies.map((i) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <img key={i.id} alt="allergen" style={{ height: "10vh" }} src={i.image} />
                      <Subtitle style={{color: context.configuration.uiConfig.primaryFont}}>{i.name}</Subtitle>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <TagList tags={meal.tags} />

            <ReactPlayer controls={true} url={meal.video} width={"100%"} height={300} style={{ maxWidth: 450 }} />

            {
              // meal.bestServed && (
              //   <div>
              //     <Title>{t("bestServed")}</Title>
              //     <div style={{ display: "flex", overflowX: "scroll" }}>
              //       {meal.bestServed.map(i => (
              //         <div key={i.id} style={{ padding: "5px" }}>
              //           <Image image={i.image} size="small" />
              //           <Subtitle>{i.name}</Subtitle>
              //         </div>
              //       ))}
              //     </div>
              //   </div>
              // )}
              // </div>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default Meal;
