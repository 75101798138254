import React from 'react';
import './Loader.css';
import Lottie from "lottie-react";
import qrLoadAnimation from "../../assets/illustrations/loadingqr.json";
 

// const Loading = () => <div className="loader" />
const Loading = () => <div className="loader-preview">
   {/* <div class="sk-folding-cube">
  <div class="sk-cube1 sk-cube"></div>
  <div class="sk-cube2 sk-cube"></div>
  <div class="sk-cube4 sk-cube"></div>
  <div class="sk-cube3 sk-cube"></div>
</div> */}
<Lottie animationData={qrLoadAnimation} />
  <p style={{textAlign: "center", fontFamily: 'Ubuntu', marginTop:  "5em"}}>Powered by do<span style={{color: "orange", fontWeight: "700", fontSize: "1.5em"}}>X</span>menu</p>
</div>

export default Loading;