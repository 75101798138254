import React, { useContext,useEffect,useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import Categories from "./components/menu/Categories";
import Meal from "./components/menu/Meal";
import MenuWrapper from "./components/menu/MenuWrapper";
import MenuChooser from "./components/menu/MenuChooser";
import GlobalContext from "./context/GlobalContext";
import Search from './components/menu/Search';
import NavbarComponent from "./components/navbar/NavbarComponent";
import FabRenderer from "../src/components/common/FabRenderer";

const Routes = () => {
  const context = useContext(GlobalContext);
  const [fabVisible, setFabVisible] = useState(false);

  useEffect(() => {
    const social = context.configuration?.restaurantDetails?.socialMedia;
  if(social){
    for (const [key,value] of Object.entries(social)) {
      if(value.enabled) setFabVisible(true);
      break;
    }
  }
  }, []);
  
  return (
    <ThemeProvider theme={context.configuration.uiConfig}>
                  {(context.configuration?.fab?.action && fabVisible) && <FabRenderer />}

    <Router>
      <Route path="/" component={NavbarComponent}/>
      <Route exact path="/:restaurantId?" component={MenuChooser} />
      <Route exact path="/:restaurantId/categories/:type" component={Categories} />
      <Route exact path="/:restaurantId/categories/:type/:category" component={MenuWrapper} />
      <Route exact path="/:restaurantId/categories/:type/:category/:itemId" component={Meal} />
      <Route exact path="/:restaurantId/tags/:tag" component={Search}/>
    </Router>
    </ThemeProvider>
  );
};

export default Routes;
