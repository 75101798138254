import styled from 'styled-components';

const CategoryButton = styled.div`
    ${'' /* background-color: ${props => (props.selected ? props.theme.primary : props.theme.secondary)}; */}
    min-width: fit-content;
    border-radius: 20px;
    padding: 1.6vh;
    margin: 0.9vw 0.9vw;
    font-size: 2vh;
    font-weight: 600;
    color: ${props => (props.selected ? props.theme.primary : props.theme.primary)};
    border-color: ${props => (props.selected ? props.theme.primary : props.theme.primary)};
    opacity : ${props => (props.selected ? "100%" : "50%")};
    border: 1px solid;
    width: max-content;
`

export default CategoryButton;