import React from "react";
import CategoryBtn from "./../common/CategoryBtn";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import TagsScroll from './../styled/TagsScroll';

const TagList = ({ tags, onSelect }) => {
  const { restaurantId, tag } = useParams();
  return (
    <>
      <TagsScroll>
        {tags.map(item => (
          <CategoryBtn
            key={item}
            title={item}
            to={"/" + restaurantId + "/tags/" + item}
            selected={item === tag}
            onPress={()=>onSelect(item)}
          />
        ))}
        </TagsScroll>
    </>
  );
};

export default TagList;

TagList.propTypes = {
  tags: PropTypes.array.isRequired, 
  onSelect: PropTypes.func
};
