import React, { useState, useContext } from "react";
import "./SearchBar.scss";
import GlobalContext from "../../context/GlobalContext";
import { searchTags } from "../../utils/APIs";

const SearchBar = () => {
  const [value, setValue] = useState("");
  const context = useContext(GlobalContext);

  const handleChange = e => {
    setValue(e.target.value);
    searchTags(context.configuration.restaurantDetails.id, [e.target.value])
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        value={value}
        placeholder="Search for tags"
        onChange={e => handleChange(e)}
      />
    </div>
  );
};

export default SearchBar;
