import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Image from "./../styled/Image";
import Card from "./../styled/Card";
import CardInfo from "../styled/CardInfo";
import CardImage from "../styled/CardImage";
import { TitleSM, Subtitle } from "../styled/Typography";
import GlobalContext from "../../context/GlobalContext";

const MealCard = ({ meal }) => {
  const { restaurantId, type, category } = useParams();
  const context = useContext(GlobalContext);

  return (
    <Card size="large" style={{ background: context.configuration.uiConfig.secondary }}>
      <Link
        to={{
          pathname:
            "/" +
            restaurantId +
            "/categories/" +
            type +
            "/" +
            category +
            "/" +
            meal.id,
          state: { meal }
        }}
        style={{ textDecoration: "none", color: "black" }}
      >
        {meal.image && <CardImage src={meal.image} />}

        <CardInfo>
          <div style={{ display: "flex", justifyContent: "space-between" }}><TitleSM style={{color: context.configuration.uiConfig.primaryFont}}>{meal.name}</TitleSM><TitleSM style={{color: context.configuration.uiConfig.primaryFont}}>{meal.price}</TitleSM></div>
          <Subtitle style={{ whiteSpace: "pre-wrap",color: context.configuration.uiConfig.secondaryFont }}>{meal.description}</Subtitle>
          {meal.allergies && (
            <div>
              <div style={{ display: "flex", overflowX: "scroll", justifyContent: "flex-start", flexWrap: "wrap" }}>
                {meal.allergies.map((i) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <img key={i.id} alt="allergen" style={{ height: "5vh", marginTop: "1.0em" }} src={i.image} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </CardInfo>

      </Link>
    </Card>
  );
};

export default MealCard;
