import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import BasicCard from "../common/BasicCard";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bgImg from "../../assets/micasabackground.jpg";

const Categories = (props) => {
  const context = useContext(GlobalContext);
  const { restaurantId, type } = useParams();
  const items = context.menu[type].categories;

  const getBackgroundStyle = () => {
    let style = {};
    if (window.location.href.includes("cafe-croatia")) {
      style.backgroundImage = `url("https://images.unsplash.com/photo-1554866585-cd94860890b7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8Y29jYSUyMGNvbGF8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&w=1000&q=80")`;
      style.backgroundSize = "cover";
      style.paddingTop = 0;
    }

    if(window.location.href.includes("mi-casa")){
      style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/micasabackground.jpeg")`;
      style.backgroundSize = "100%";
      style.backgroundPosition = "center";
      //style.backgroundRepeat = "no-repeat";
      style.paddingTop = 0;
      style.backgroundAttachment = "fixed"
    }

    if(window.location.href.includes("oriental")){
      style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/OrientalPozadina.png")`;
      style.backgroundSize = "100%";
      style.backgroundPosition = "center";
      style.backgroundRepeat = "no-repeat";
      style.backgroundAttachment = "fixed"
    }
    

    if(window.location.href.includes("lokal45")&& localStorage.getItem("redBullDemo")){
      style = JSON.parse(localStorage.getItem("redBullDemo"));  
    }

    return style;
  };

  useEffect(() => {
    //window.scrollTo(0, 0)
    //console.log("brane")
    //window.scrollTo({top: 0, behavior: 'smooth'});
    document.documentElement.scrollTo(0, 0);
    //document.querySelector('body').scrollTo(0,0);
  }, []);

  const renderItems = () => {
    return items.map((item) => (
      <LazyLoadComponent>
        <BasicCard
          to={{
            pathname:
              "/" +
              restaurantId +
              "/categories/" +
              type +
              "/" +
              item.categoryId,
            state: item,
          }}
          image={
            (item.image !== "null" && item.image) ||
            (item.items !== undefined &&
              item.items[0] !== undefined &&
              item.items[0].image)
          }
          title={item.name}
        />
      </LazyLoadComponent>
    ));
  };

  return (
    
        <div className="scroll-content" style={getBackgroundStyle()}>
          {renderItems()}
        </div>
    
  );
};

export default Categories;
