import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./assets/translations/en.json";
import translationMk from "./assets/translations/mk.json";
import translationAl from "./assets/translations/al.json";
import translationGr from "./assets/translations/gr.json";
import translationFr from './assets/translations/fr.json';
import translationIt from './assets/translations/it.json';
import translationRu from './assets/translations/ru.json';
import translationSl from './assets/translations/sl.json';
import translationEs from './assets/translations/es.json';
import translationTr from './assets/translations/tr.json';
import translationAt from './assets/translations/at.json';
import translationDe from './assets/translations/de.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEn,
    },
    mk: {
      translation: translationMk,
    },
    al: {
      translation: translationAl,
    },
    gr: {
      translation: translationGr,
    },
    fr: {
      translation: translationFr
    },
    it: {
      translation: translationIt
    },
    ru: {
      translation: translationRu
    },
    sl: {
      translation: translationSl
    },
    es: {
      translation: translationEs
    },
    tr: {
      translation: translationTr
    },
    at: {
      translation: translationAt
    },
    de: {
      translation: translationDe
    }
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});