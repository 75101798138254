import React, { useState, useEffect, useContext } from "react";
import { Typography, Dialog, DialogContent, InputAdornment, TextField, Button, makeStyles } from "@material-ui/core";
import GlobalContext from "../../context/GlobalContext";
import { Person, Phone, Email } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { OnLoadDialogType } from "../../context/GlobalState";
import Icon from "@material-ui/core/Icon";
import { createClientLog } from "../../utils/APIs";

const useStyles = makeStyles(() => ({
    textField: {
        // maxWidth: 300,
        width: '100%',
        marginBottom: 20
    },
    title: {
        textAlign: "center",
        marginBottom: 20
    }
}));

const initialClientInfo = {
    fullName: "",
    phoneNumber: "",
    email: "",
    tableNumber: ""
}

const ClientLogDialog = ({ }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const context = useContext(GlobalContext);
    const [openDialog, setOpenDialog] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [clientInfo, setClientInfo] = useState(initialClientInfo);
    const [onLoadDialogTurn, setOnLoadDialogTurn] = useState(false);
    const [restaurantId, setRestaurantId] = useState(null);
    // Used so user will not be able to click the Access menu btn more than once
    const [sendingRequest, setSendingRequest] = useState(false);

    useEffect(() => {
        setRestaurantId(context.configuration.restaurantDetails.id);
        handleOpen();
    }, []);

    useEffect(() => {
        const dialogConfig = context.onLoadDialogs.sort((a, b) => a.order - b.order);
        for (const config of dialogConfig) {
            if (config.show === true && config.dialog_type !== OnLoadDialogType.CLIENT_LOG) {
                setOnLoadDialogTurn(false);
                break;
            } else if (config.show === true && config.dialog_type === OnLoadDialogType.CLIENT_LOG) {
                setOnLoadDialogTurn(true);
                break;
            }
        }
    }, [context.onLoadDialogs]);

    const handleOpen = async () => {
        const shouldOpen = context.configuration.restaurantDetails.clientLogEnabled ? true : false;
        setOpenDialog(shouldOpen);
        if(!shouldOpen){
            // If client log feature is not enabled, skip turn in global OnLoadDialogs
            context.setOnLoadDialogs(OnLoadDialogType.CLIENT_LOG, false);
        }
    };

    const handleChange = (event) => {
        // Using a new object so than we can perform the validation immediately
        const newClientInfo = { ...clientInfo, [event.target.name]: event.target.value };
        setClientInfo(newClientInfo);
        validateForm(newClientInfo);
    }

    const handleClick = async () => {
        // Disable button
        setSendingRequest(true);
        await createClientLog({ ...clientInfo, restaurantId }).catch(e => console.log(e));
        setOpenDialog(false);
        // Dont display CLIENT_LOG dialog anymore
        context.setOnLoadDialogs(OnLoadDialogType.CLIENT_LOG, false);
    }

    const validateForm = (formData) => {
        for (const key in formData) {
            if (formData[key] === "") {
                setIsFormValid(false);
                return false;
            }
        }
        setIsFormValid(true);
        return true;
    }

    return (
        <Dialog
            open={openDialog && onLoadDialogTurn}
            aria-labelledby="form-dialog-title"
            maxWidth="xs">
            <DialogContent>
                <Typography variant="h6" className={classes.title}>
                    {t("clientDialogTitle")}
                </Typography>
                <form autoComplete="off">
                    <TextField
                        required
                        className={classes.textField}
                        type="text"
                        variant="outlined"
                        id="fullName"
                        name="fullName"
                        label={t("fullName")}
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Person />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        required
                        className={classes.textField}
                        type="text"
                        variant="outlined"
                        id="phoneNumber"
                        name="phoneNumber"
                        label={t("phoneNumber")}
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Phone />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        required
                        className={classes.textField}
                        type="text"
                        variant="outlined"
                        id="email"
                        name="email"
                        label={t("email")}
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        required
                        className={classes.textField}
                        type="text"
                        variant="outlined"
                        id="tableNumber"
                        name="tableNumber"
                        label={t("tableNumber")}
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className="fas fa-chair" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div style={{ textAlign: "center", marginBottom: 15 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleClick}
                            disabled={!isFormValid || sendingRequest}
                            style={{ borderRadius: 20 }}>
                            {t("accessMenu")}
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default ClientLogDialog;