import React, { useContext, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import BasicCard from "./../common/BasicCard";
import { useParams } from "react-router-dom";
import { MainTitle } from "../styled/Typography";
import PopUpAdDialog from "./PopUpAdDialog";
import ClientLogDialog from "./ClientLogDialog";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const MenuChooser = (props) => {
  const context = useContext(GlobalContext);
  const { restaurantId } = useParams();

  let tint = props?.tintScreen;

  const renderLogo = () => {
    const logoUrl = context.configuration.restaurantDetails.logo;

    const noLogo = logoUrl === "" || logoUrl === null || logoUrl === undefined;
    return (
      <div style={{ filter: context.blur}}>
        {noLogo ? (
          <div style={{ paddingTop: 30, paddingBottom: 30 }}>
            <MainTitle>
              {context.configuration.restaurantDetails.name}
            </MainTitle>
          </div>
        ) : (
          // <img src={logoUrl} style={{ height: "auto", width: "100%", paddingTop: 0 }} />
          <LazyLoadImage
            effect="blur"
            style={{ height: "auto", width: "100%", paddingTop: 0 }}
            src={logoUrl} // use normal <img> attributes as props
            width="100%"
          />
        )}
      </div>
    );
  };
  useEffect(() => {
    //window.scrollTo(0, 0)
    //console.log("brane")
    //window.scrollTo({top: 0, behavior: 'smooth'});
    document.documentElement.scrollTo(0, 0);
    //document.querySelector('body').scrollTo(0,0);
  }, []);
  const renderItems = () => {
    return Object.keys(context.menu).map((obj) => (
      <BasicCard
        key={obj}
        to={"/" + restaurantId + "/categories" + context.menu[obj].url}
        title={context.menu[obj].name}
        image={context.menu[obj].image}
      />
    ));
  };


  const renderPopUpAdDialog = () => {
    return <PopUpAdDialog popUpAd={context.configuration.popUpAd} />;
  };

  const renderClientLogDialog = () => {
    return <ClientLogDialog />;
  };

  const getBackgroundStyle = () => {
    let style = { paddingTop: 0 };
    style.filter = tint ? "blur(1.3rem)" : ""
    if (window.location.href.includes("cafe-croatia")) {
      style.backgroundImage = `url("https://images.unsplash.com/photo-1554866585-cd94860890b7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8Y29jYSUyMGNvbGF8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&w=1000&q=80")`;
      style.backgroundSize = "cover";
    }
    if (window.location.href.includes("mi-casa")) {
      style.position = "relative";
      // style.zIndex = 51302;
    }

    if(window.location.href.includes("mi-casa")){
      style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/micasabackground.jpeg")`;
      style.backgroundSize = "100%";
      style.backgroundPosition = "center";
      style.backgroundRepeat = "no-repeat";
      style.backgroundAttachment = "fixed"
    }

    if(window.location.href.includes("oriental")){
      style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/OrientalPozadina.png")`;
      style.backgroundSize = "100%";
      style.backgroundPosition = "center";
      style.backgroundRepeat = "no-repeat";
      style.zIndex = 50302;
      style.backgroundAttachment = "fixed"
    }

    if(window.location.href.includes("doner-house")){
      style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/donerhouse.jpg")`;
      style.backgroundSize = "cover";
      style.backgroundPosition = "center";
      style.backgroundRepeat = "no-repeat";
      style.zIndex = 50302;
      style.backgroundAttachment = "fixed"
    }

    if(window.location.href.includes("lokal45")){

      switch (window.location.search) {
        case "?demo1":
          style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/Redbull_Pattern_SingleCan.jpg")`;
          // style.backgroundSize ="cover";
          break;
        case "?demo2":
          style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/RedBull_Pattern_ActionPhoto.jpg")`;
          // style.backgroundSize ="cover";
          break;
        case "?demo3":
          style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/RedBull_Pattern_Cans.jpg")`;
          // style.backgroundSize ="cover";
          break;
        case "?demo4":
          style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/RedBull_Pattern_Logos_BNW.jpg")`;
          // style.backgroundSize ="cover";
          break;
        case "?demo5":
          style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/RedBull_Pattern_Logos.jpg")`;
          // style.backgroundSize ="cover";
          break;

        default:
          localStorage.removeItem("redBullDemo");
          break;
      }
    }
    if (window.location.href.includes("lokal45") && style.backgroundImage) {
      localStorage.setItem("redBullDemo", JSON.stringify(style));
    }
    return style;
  };

  // let it stay, we might need it.

  // const setUpWebSocket = async () => {
  //   console.log("SET UP WEB SOCKET");
  //   var W3CWebSocket = require("websocket").w3cwebsocket;

  //   var client = new W3CWebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}`);

  //   return client;
  // };


  return  <div className="scroll-content" style={getBackgroundStyle()}>
      {renderLogo()}
      {renderItems()}
      {renderPopUpAdDialog()}
      {renderClientLogDialog()}
    </div>
};

export default MenuChooser;
