import React from "react";
import Meals from "./Meals";
import CategoriesScroll from "./CategoriesScroll";

const MenuWrapper = (props) => {
  return (
    <div>
      <CategoriesScroll />
      <Meals />
    </div>
  );
};

export default MenuWrapper;
