import styled from "styled-components";

const small = {
  height: '12vh',
  width: '12vh'
}

const medium = {
  height: '220px',
  width: '220px'
}

const large = {
  height: '45vh',
  width: '100%'
}

const background = {
  // height: '40vh',
  width: '80vw'
}

/**
 * @deprecated
 */
const Image = styled.div`
  background-image: ${props=> 'url(' + props.image +')'};
  ${'' /* width: ${props=> (props.size === 'small') ? small.width : props.size === 'large' ? large.width :props.size === 'background' ? background.width : medium.width} */}
  height: ${props=> (props.size === 'small') ? small.height : props.size === 'large' ? large.height : props.size === 'background' ? background.height :medium.height}
  ${'' /* border-radius: 10px; */}
  background-size: cover;
  background-position: center;
  ${'' /* -webkit-mask: -webkit-gradient(linear, center top, center bottom, color-stop(0.00, rgba(0, 0, 0, 1)), color-stop(0.35, rgba(0, 0, 0, 1)), color-stop(0.50, rgba(0, 0, 0, 1)), color-stop(0.65, rgba(0, 0, 0, 1)), color-stop(1.00, rgba(0, 0, 0, 0))); */}

`;

export default Image;
