import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import CategoryBtn from "../common/CategoryBtn";
import TagsScroll from "./../styled/TagsScroll";
import bgImg from "../../assets/micasabackground.jpg";

const CategoriesScroll = () => {
  const context = useContext(GlobalContext);
  const { type, category, restaurantId } = useParams();

  const getBackgroundStyle = () => {
    let style = { position: "fixed", top: 59, zIndex: 20, width: "100%", filter: context.blur }
    if(window.location.href.includes("cafe-croatia")){
      style.backgroundImage = `url("https://images.unsplash.com/photo-1554866585-cd94860890b7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8Y29jYSUyMGNvbGF8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&w=1000&q=80")`;
    }
    if(window.location.href.includes("mi-casa")){
      style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/micasabackground.jpeg")`;
      style.backgroundSize = "100%";
      style.backgroundPosition = "center";
      //style.backgroundRepeat = "no-repeat";
      style.backgroundAttachment = "fixed"
    }

    if(window.location.href.includes("oriental")){
      style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/OrientalPozadina.png")`;
      style.backgroundSize = "100%";
      style.backgroundPosition = "center";
      style.backgroundRepeat = "no-repeat";
      style.backgroundAttachment = "fixed"
    }

    if(window.location.href.includes("lokal45") && localStorage.getItem("redBullDemo")){
      const saved = JSON.parse(localStorage.getItem("redBullDemo"));
      delete saved.paddingTop
    style = {...style,...saved};  
    }
    return style;
  }
  const renderCategories = () => {
    return (
      <div
        style={getBackgroundStyle()}
        className="slide-in-right"
      >
        <TagsScroll style={{ overflowX: "scroll", overflowY: "hidden", backgroundColor: context.configuration.uiConfig.backgroundColor }}>
          {context.menu[type].categories.map((c) => (
            <CategoryBtn
              title={c.name}
              to={"/" + restaurantId + "/categories/" + type + "/" + c.categoryId}
              selected={category === c.categoryId}
              key={c.categoryId}
            />
          ))}
        </TagsScroll>
      </div>
    );
  };

  return renderCategories();
};

export default CategoriesScroll;
