const meals = [
  {
    id: "1",
    mealId: "1",
    name: "Mushroom Pasta",
    description: "Delicious mushroom pasta with onion sauce",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "2",
    mealId: "2",
    name: "Alio Olio Pasta",
    description: "Delicious alio olio pasta",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "3",
    mealId: "3",
    name: "Mushroom Pasta",
    description: "Delicious mushroom pasta with onion sauce",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "4",
    mealId: "4",
    name: "Mushroom Pasta",
    description: "Delicious mushroom pasta with onion sauce",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "5",
    mealId: "5",
    name: "Alio Olio Pasta",
    description: "Delicious alio olio pasta",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "6",
    mealId: "6",
    name: "Mushroom Pasta",
    description: "Delicious mushroom pasta with onion sauce",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "7",
    mealId: "7",
    name: "Mushroom Pasta",
    description: "Delicious mushroom pasta with onion sauce",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "8",
    mealId: "8",
    name: "Alio Olio Pasta",
    description: "Delicious alio olio pasta",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "9",
    mealId: "9",
    name: "Mushroom Pasta",
    description: "Delicious mushroom pasta with onion sauce",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "10",
    mealId: "10",
    name: "Mushroom Pasta",
    description: "Delicious mushroom pasta with onion sauce",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "11",
    mealId: "11",
    name: "Alio Olio Pasta",
    description: "Delicious alio olio pasta",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  },
  {
    id: "12",
    mealId: "12",
    name: "Mushroom Pasta",
    description: "Delicious mushroom pasta with onion sauce",
    language: "EN",
    translated: false,
    price: 20,
    quantity: 1,
    image:
      "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
    ingredients: [
      {
        id: "1",
        name: "mushrooms",
        amount: 2,
        removable: true,
        included: true
      },
      { id: "2", name: "pepper", amount: 1, removable: true, included: true },
      {
        id: "3",
        name: "tomato sauce",
        amount: 1,
        removable: true,
        included: true
      },
      { id: "4", name: "pasta", amount: 1, removable: false, included: true }
    ]
  }
];

const uiConf = {
  // primary: 'gold',
  // secondary: 'whitesmoke',
  // primaryFont: 'white',
  // secondaryFont: 'black',
  // cardSize: '80vw'
  
  primary: 'coral',
  secondary: 'whitesmoke',
  primaryFont: 'white',
  secondaryFont: 'black',
  cardSize: '80vw'

  // primary: 'green',
  // secondary: 'whitesmoke',
  // primaryFont: 'white',
  // secondaryFont: 'black',
  // cardSize: '80vw'
}

const languages = [
  { id: "en", name: "🇬🇧" },
  { id: "mk", name: "🇲🇰" },
  { id: "gr", name:"🇬🇷" },
  { id: "al", name: "🇦🇱" }
  
];

const restaurantConf = {
  restaurantDetails: {
    name: "Amigos",
    logo: "https://static1.squarespace.com/static/585e5f2ff7e0ab0e6acbb466/t/59d5f1ce90bade831ced2a9e/1557671788743/?format=1500w"
  },
  uiConf: {  
    primary: 'coral',
    secondary: 'whitesmoke',
    primaryFont: 'white',
    secondaryFont: 'black',
    cardSize: '80vw'
  },
  languages: [
    { id: "en", name: "🇬🇧" },
    { id: "mk", name: "🇲🇰" },
    { id: "gr", name:"🇬🇷" },
    { id: "al", name: "🇦🇱" }
  ]
}

const menus = {
  'en': [
    {
      id: 1,
      name: 'Food',
      url: '/food',
      image: 'https://media.cntraveler.com/photos/5a749dfbfed07977f3e32808/4:3/w_420,c_limit/Chicon_Kate-LeSueur_2018_315A6147.jpg'
    },
    {
      id: 2,
      name: 'Drinks',
      url: '/drinks',
      image: 'https://ca-times.brightspotcdn.com/dims4/default/a36d8fb/2147483647/strip/true/crop/2530x2680+0+0/resize/1403x1486!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Fe7%2Ff8%2F5524621740918e954cce9f156f3b%2Fholiday-drinks-marine-room-2019-cropped-jpg.jpg'
    },
  ],
  'mk': [
    {
      id: 1,
      name: 'Храна',
      url: '/food',
      image: 'https://media.cntraveler.com/photos/5a749dfbfed07977f3e32808/4:3/w_420,c_limit/Chicon_Kate-LeSueur_2018_315A6147.jpg'
    },
    {
      id: 2,
      name: 'Пијалоци',
      url: '/drinks',
      image: 'https://ca-times.brightspotcdn.com/dims4/default/a36d8fb/2147483647/strip/true/crop/2530x2680+0+0/resize/1403x1486!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Fe7%2Ff8%2F5524621740918e954cce9f156f3b%2Fholiday-drinks-marine-room-2019-cropped-jpg.jpg'
    },
  ]
}

const categories = {
  'en': {
    categories: [
      {
        id: 1,
        name: "Breakfast",
        meals: [
          {
            id: "100",
            mealId: "100",
            name: "Amigos Breakfast",
            description:
              "Healthy breakfast of two eggs (sunny side up), guacamole, rocket, whole grain bread and your choice of salmon or prosciutto. Served with dips and coffee.",
            language: "EN",
            translated: false,
            price: 250,
            quantity: 1,
            image:
              "https://ichef.bbci.co.uk/wwfeatures/live/976_549/images/live/p0/6s/kw/p06skw9l.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ],
            bestServed: [
              {id: 1, name: "Coca Cola", image: "https://i7.pngguru.com/preview/410/251/770/coca-cola-whopper-fizzy-drinks-hamburger-fanta-coca.jpg"},
              {id: 2, name: "Coffee", image: "https://images.pexels.com/photos/414720/pexels-photo-414720.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
              {id: 3, name: "Dessert", image: "https://omgchocolatedesserts.com/wp-content/uploads/2015/08/Chocolate-Poke-Cake-2.jpg"},
              {id: 4, name: "Coca Cola", image: "https://i7.pngguru.com/preview/410/251/770/coca-cola-whopper-fizzy-drinks-hamburger-fanta-coca.jpg"},
              {id: 5, name: "Coffee", image: "https://images.pexels.com/photos/414720/pexels-photo-414720.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
              {id: 6, name: "Dessert", image: "https://omgchocolatedesserts.com/wp-content/uploads/2015/08/Chocolate-Poke-Cake-2.jpg"}
            ],
            allergens: [
              {id: 1, name: "Eggs", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoHuevo-Egg_icon-icons.com_67598.png'},
              {id: 2, name: "Lactose", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoLacteos-DairyProducts_icon-icons.com_67597.png'},
              {id: 3, name: "Soy", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/Soy_icon-icons.com_67593.png'}
            ],
          },
          {
            id: "200",
            mealId: "200",
            name: "Breakfast Burrito",
            description:
              "Tortilla wrap filled with scrambeled eggs, guacamole, bacon and melted cheese, served with creme cheese and salsa.",
            language: "EN",
            translated: false,
            price: 150,
            quantity: 1,
            image:
              "https://images-gmi-pmc.edge-generalmills.com/8f629f3c-ec68-4f76-9744-6d715056c9d4.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ],
            bestServed: [
              {id: 1, name: "Coca Cola", image: "https://i7.pngguru.com/preview/410/251/770/coca-cola-whopper-fizzy-drinks-hamburger-fanta-coca.jpg"},
              {id: 2, name: "Coffee", image: "https://images.pexels.com/photos/414720/pexels-photo-414720.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
              {id: 3, name: "Dessert", image: "https://omgchocolatedesserts.com/wp-content/uploads/2015/08/Chocolate-Poke-Cake-2.jpg"},
              {id: 4, name: "Coca Cola", image: "https://i7.pngguru.com/preview/410/251/770/coca-cola-whopper-fizzy-drinks-hamburger-fanta-coca.jpg"},
              {id: 5, name: "Coffee", image: "https://images.pexels.com/photos/414720/pexels-photo-414720.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
              {id: 6, name: "Dessert", image: "https://omgchocolatedesserts.com/wp-content/uploads/2015/08/Chocolate-Poke-Cake-2.jpg"}
            ],
            allergens: [
              {id: 1, name: "Eggs", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoHuevo-Egg_icon-icons.com_67598.png'},
              {id: 2, name: "Lactose", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoLacteos-DairyProducts_icon-icons.com_67597.png'},
              {id: 3, name: "Soy", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/Soy_icon-icons.com_67593.png'}
            ],
          },
          {
            id: "300",
            mealId: "300",
            name: "Avocado Breakfast",
            description:
              "Healthy breakfast of guacamole and salmon served over two slices of whole grain bread.",
            language: "EN",
            translated: false,
            price: 150,
            quantity: 1,
            image:
              "https://www.inspiredtaste.net/wp-content/uploads/2018/08/Avocado-Toast-Recipe-1200.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ],
            bestServed: [
              {id: 1, name: "Coca Cola", image: "https://i7.pngguru.com/preview/410/251/770/coca-cola-whopper-fizzy-drinks-hamburger-fanta-coca.jpg"},
              {id: 2, name: "Coffee", image: "https://images.pexels.com/photos/414720/pexels-photo-414720.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
              {id: 3, name: "Dessert", image: "https://omgchocolatedesserts.com/wp-content/uploads/2015/08/Chocolate-Poke-Cake-2.jpg"},
              {id: 4, name: "Coca Cola", image: "https://i7.pngguru.com/preview/410/251/770/coca-cola-whopper-fizzy-drinks-hamburger-fanta-coca.jpg"},
              {id: 5, name: "Coffee", image: "https://images.pexels.com/photos/414720/pexels-photo-414720.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
              {id: 6, name: "Dessert", image: "https://omgchocolatedesserts.com/wp-content/uploads/2015/08/Chocolate-Poke-Cake-2.jpg"}
            ],
            allergens: [
              {id: 1, name: "Eggs", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoHuevo-Egg_icon-icons.com_67598.png'},
              {id: 2, name: "Lactose", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoLacteos-DairyProducts_icon-icons.com_67597.png'},
              {id: 3, name: "Soy", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/Soy_icon-icons.com_67593.png'}
            ],
          },
          {
            id: "200",
            mealId: "200",
            name: "Eggs Benedict",
            description:
              "Two poached eggs and bacon served over bread, covered with holandese sauce.",
            language: "EN",
            translated: false,
            price: 150,
            quantity: 1,
            image:
              "https://prods3.imgix.net/images/articles/2017_08/Non-Feature-eggs-benedict-recipe-breakfast1.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ],
            bestServed: [
              {id: 1, name: "Coca Cola", image: "https://i7.pngguru.com/preview/410/251/770/coca-cola-whopper-fizzy-drinks-hamburger-fanta-coca.jpg"},
              {id: 2, name: "Coffee", image: "https://images.pexels.com/photos/414720/pexels-photo-414720.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
              {id: 3, name: "Dessert", image: "https://omgchocolatedesserts.com/wp-content/uploads/2015/08/Chocolate-Poke-Cake-2.jpg"}
            ],
            allergens: [
              {id: 1, name: "Eggs", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoHuevo-Egg_icon-icons.com_67598.png'},
              {id: 2, name: "Lactose", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoLacteos-DairyProducts_icon-icons.com_67597.png'},
              {id: 3, name: "Soy", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/Soy_icon-icons.com_67593.png'}
            ],
          },
          {
            id: "200",
            mealId: "200",
            name: "Wrap",
            description:
              "Fresh tortilla wrap filled with chicken, grilled veggies, cheese, mushrooms and creme cheese.",
            language: "EN",
            translated: false,
            price: 150,
            quantity: 1,
            image: "https://s3.envato.com/files/259783279/21821.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          }
        ],
        bestServed: [
          {id: 1, name: "Coca Cola", image: "https://i7.pngguru.com/preview/410/251/770/coca-cola-whopper-fizzy-drinks-hamburger-fanta-coca.jpg"},
          {id: 2, name: "Coffee", image: "https://images.pexels.com/photos/414720/pexels-photo-414720.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
          {id: 3, name: "Dessert", image: "https://omgchocolatedesserts.com/wp-content/uploads/2015/08/Chocolate-Poke-Cake-2.jpg"}
        ],
        allergens: [
          {id: 1, name: "Eggs", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoHuevo-Egg_icon-icons.com_67598.png'},
          {id: 2, name: "Lactose", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoLacteos-DairyProducts_icon-icons.com_67597.png'},
          {id: 3, name: "Soy", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/Soy_icon-icons.com_67593.png'}
        ],
      },
      {
        id: 2,
        name: "Pasta",
        meals: [
          {
            id: "1",
            mealId: "1",
            name: "Mushroom Pasta",
            description: "Delicious mushroom pasta with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,

            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ],
            allergens: [
              {id: 1, name: "Eggs", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoHuevo-Egg_icon-icons.com_67598.png'},
              {id: 2, name: "Lactose", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/IconoAlergenoLacteos-DairyProducts_icon-icons.com_67597.png'},
              {id: 3, name: "Soy", image: 'https://cdn.icon-icons.com/icons2/852/PNG/128/Soy_icon-icons.com_67593.png'}
            ],
          },
          {
            id: "2",
            mealId: "2",
            name: "Alio Olio Pasta",
            description: "Delicious alio olio pasta",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://cdn.apartmenttherapy.info/image/fetch/f_jpg,q_auto:eco,c_fill,g_auto,w_1500,ar_1:1/https%3A%2F%2Fstorage.googleapis.com%2Fgen-atmedia%2F3%2F2018%2F07%2Fe77cb676a9408994b6b07b673d46d17bdbf8814c.jpeg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "3",
            mealId: "3",
            name: "Mushroom Pasta",
            description: "Delicious mushroom pasta with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "4",
            mealId: "4",
            name: "Mushroom Pasta",
            description: "Delicious mushroom pasta with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "5",
            mealId: "5",
            name: "Alio Olio Pasta",
            description: "Delicious alio olio pasta",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "6",
            mealId: "6",
            name: "Mushroom Pasta",
            description: "Delicious mushroom pasta with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "7",
            mealId: "7",
            name: "Mushroom Pasta",
            description: "Delicious mushroom pasta with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "8",
            mealId: "8",
            name: "Alio Olio Pasta",
            description: "Delicious alio olio pasta",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "9",
            mealId: "9",
            name: "Mushroom Pasta",
            description: "Delicious mushroom pasta with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "10",
            mealId: "10",
            name: "Mushroom Pasta",
            description: "Delicious mushroom pasta with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "11",
            mealId: "11",
            name: "Alio Olio Pasta",
            description: "Delicious alio olio pasta",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "12",
            mealId: "12",
            name: "Mushroom Pasta",
            description: "Delicious mushroom pasta with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-Pasta-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "mushrooms",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "pepper",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "tomato sauce",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "pasta",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          }
        ]
      },
      { id: 5, name: "Desserts", meals: [] },
      { id: 7, name: "Wine", meals: [] },
      { id: 6, name: "Drinks", meals: [] },
      { id: 8, name: "Desserts", meals: [] },
      { id: 9, name: "Desserts", meals: [] },
      { id: 10, name: "Desserts", meals: [] },
      { id: 11, name: "Desserts", meals: [] },
      { id: 12, name: "Desserts", meals: [] },
      { id: 13, name: "Desserts", meals: [] },
      { id: 14, name: "Desserts", meals: [] }
    ]
  },
  'mk': {
    categories: [
      {
        id: 1,
        name: "Појадок",
        meals: [
          {
            id: "100",
            mealId: "100",
            name: "Појадок Амигос",
            description: "Здрав појадок на две јајца (сончева страна нагоре), гуакамол, ракета, леб од цело зрно и ваш избор на лосос или пршуто. Служено со кафе.",
            language: "MK",
            translated: false,
            price: 250,
            quantity: 1,
            image:
              "https://ichef.bbci.co.uk/wwfeatures/live/976_549/images/live/p0/6s/kw/p06skw9l.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "200",
            mealId: "200",
            name: "Бурито појадок",
            description: "Тортила завиткана исполнета со пржени јајца, гуакамол, сланина и растопено сирење, сервирано со крем сирење и салса.",
            language: "EN",
            translated: false,
            price: 150,
            quantity: 1,
            image:
              "https://images-gmi-pmc.edge-generalmills.com/8f629f3c-ec68-4f76-9744-6d715056c9d4.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "300",
            mealId: "300",
            name: "Авокадо појадок",
            description: "Здрав појадок на гуакамол и лосос послужен над две парчиња леб од цели зрна.",
            language: "EN",
            translated: false,
            price: 150,
            quantity: 1,
            image:
              "https://www.inspiredtaste.net/wp-content/uploads/2018/08/Avocado-Toast-Recipe-1200.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "200",
            mealId: "200",
            name: "Бенедикт",
            description: "Две јајца и сланина служеа над леб, покриен со сос од холандијаза.",
            language: "EN",
            translated: false,
            price: 150,
            quantity: 1,
            image:
              "https://prods3.imgix.net/images/articles/2017_08/Non-Feature-eggs-benedict-recipe-breakfast1.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "200",
            mealId: "200",
            name: "Врап",
            description: "Свежа обвивка од тортилја исполнета со пилешко месо, скара зеленчук, сирење, печурки и крем сирење.",
            language: "EN",
            translated: false,
            price: 150,
            quantity: 1,
            image:
              "https://s3.envato.com/files/259783279/21821.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          }
        ]
      },
      {
        id: 2,
        name: "Паста",
        meals: [
          {
            id: "1",
            mealId: "1",
            name: "Паста со печурки",
            description: "Вкусна паста од печурки со лук сос",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-паста-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "2",
            mealId: "2",
            name: "Alio Olio паста",
            description: "Вкусна alio olio паста",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://cdn.apartmenttherapy.info/image/fetch/f_jpg,q_auto:eco,c_fill,g_auto,w_1500,ar_1:1/https%3A%2F%2Fstorage.googleapis.com%2Fgen-atmedia%2F3%2F2018%2F07%2Fe77cb676a9408994b6b07b673d46d17bdbf8814c.jpeg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "3",
            mealId: "3",
            name: "Mushroom паста",
            description: "Delicious mushroom паста with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-паста-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "4",
            mealId: "4",
            name: "Mushroom паста",
            description: "Delicious mushroom паста with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-паста-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "5",
            mealId: "5",
            name: "Alio Olio паста",
            description: "Delicious alio olio паста",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "6",
            mealId: "6",
            name: "Mushroom паста",
            description: "Delicious mushroom паста with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-паста-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "7",
            mealId: "7",
            name: "Mushroom паста",
            description: "Delicious mushroom паста with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-паста-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "8",
            mealId: "8",
            name: "Alio Olio паста",
            description: "Delicious alio olio паста",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "9",
            mealId: "9",
            name: "Mushroom паста",
            description: "Delicious mushroom паста with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-паста-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "10",
            mealId: "10",
            name: "Mushroom паста",
            description: "Delicious mushroom паста with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-паста-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "11",
            mealId: "11",
            name: "Alio Olio паста",
            description: "Delicious alio olio паста",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2012/8/22/1/BX0707H_spaghetti-aglio-e-olio_s4x3.jpg.rend.hgtvcom.826.620.suffix/1487192969834.jpeg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          },
          {
            id: "12",
            mealId: "12",
            name: "Mushroom паста",
            description: "Delicious mushroom паста with onion sauce",
            language: "EN",
            translated: false,
            price: 20,
            quantity: 1,
            image:
              "https://assets.bonappetit.com/photos/5d4ddd602c815a00080f9771/16:9/w_1028,c_limit/BA-0919-Creamy-паста-Crispy-Mushroom-Playbook.jpg",
            ingredients: [
              {
                id: "1",
                name: "печурки",
                amount: 2,
                removable: true,
                included: true
              },
              {
                id: "2",
                name: "пиперка",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "3",
                name: "сос од домат",
                amount: 1,
                removable: true,
                included: true
              },
              {
                id: "4",
                name: "паста",
                amount: 1,
                removable: false,
                included: true
              }
            ]
          }
        ]
      },
      { id: 5, name: "Десерти", meals: [] },
      { id: 7, name: "Вино", meals: [] },
      { id: 6, name: "Пијалоци", meals: [] },
      { id: 8, name: "Десерти", meals: [] },
      { id: 9, name: "Десерти", meals: [] },
      { id: 10, name: "Десерти", meals: [] },
      { id: 11, name: "Десерти", meals: [] },
      { id: 12, name: "Десерти", meals: [] },
      { id: 13, name: "Десерти", meals: [] },
      { id: 14, name: "Десерти", meals: [] }
    ]
  }
};








const drinks = {
  'en': {
    categories: [
      {
        id: "ef2874bd-dd2c-4e13-a5fd-914b3c2528ae",
            restaurant: "be2b9522-c069-48bd-b5c6-9f7da1221a59",
                categories: {
            en: [
                {
                    id: "ea402a4a-eb57-4c4e-b226-3f8a5a2b7090",
                    categoryId: "2733ebc1-db40-4d42-84b4-35ac13664066",
                    name: "cocktails",
                    language: "en",
                    drinks: [
                        {
                            id: "25e2344d-c071-4ce1-b9d2-3f203df6e37f",
                            drinkId: "0108ca13-6253-40d8-8dad-895643e06d38",
                            name: "Black Russian",
                            description: "Gin (45) Maraschino (15) Lime (15)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.eu-central-1.amazonaws.com/1579258792.162-coctaiol_3.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "1a2ca183-d9a6-442e-9e5a-624bd7983120",
                                    ingredientId: "630244f0-1043-439f-bb54-dd833b0752ac",
                                    name: "Lime",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "c2747925-9ccf-4493-adf2-dd28d4dd4f1a",
                                    ingredientId: "79302515-0dd2-4480-87f5-432e4e751ee7",
                                    name: "Maraschino",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "c90bf208-ef0a-431a-800c-81fd60ea36e2",
                                    ingredientId: "784d9479-b039-4a4d-b0a2-1614187d5a66",
                                    name: "Gin",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        },
                        {
                            id: "39a622d5-0e96-4284-a4fb-80ff5a8e0634",
                            drinkId: "1c2573fb-998f-444f-a05b-13e9eafd69e2",
                            name: "Bronks",
                            description: "Gin (45) Maraschino (15) Lime (15)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.eu-central-1.amazonaws.com/1579258856.647-2017_16.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "0374e3db-156b-46fb-be06-923fad114d95",
                                    ingredientId: "5b4a4be8-4481-4d3e-80e8-6a42df338167",
                                    name: "Gin",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "ae7b5b64-e8b6-4a9f-9649-9e6681a742be",
                                    ingredientId: "8fb3b7d3-abee-496b-960f-22fee7b2e498",
                                    name: "Maraschino",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "de706ff8-5193-4b0b-b6d9-60983144b1eb",
                                    ingredientId: "f563c555-8c59-40a0-aa1a-b85055895d46",
                                    name: "Lime",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        },
                        {
                            id: "4f546632-d2bb-4fcb-b467-5bfbc0e1db04",
                            drinkId: "5ed3aaee-c29f-4153-bef7-6ab31375c365",
                            name: "Barracuda",
                            description: "Gin (45) Maraschino (15) Lime (15)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.eu-central-1.amazonaws.com/1579258737.406-coctail-one.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "5e271a40-af18-47b9-bfd4-4186856990e5",
                                    ingredientId: "243a5332-1fae-4176-adb0-1602dcc9cf74",
                                    name: "Gin",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "7340d07c-b079-42c8-a610-56345da905bf",
                                    ingredientId: "26638c07-fada-41d0-9003-664a3d99eb19",
                                    name: "Lime",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "aedebabc-dbb2-4011-83f1-dfdb6f674662",
                                    ingredientId: "23b408a4-d470-409b-9c71-f37f1a4d5df6",
                                    name: "Maraschino",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        },
                        {
                            id: "615745f0-267e-4a46-8958-b83553b287f1",
                            drinkId: "2ec128a6-869e-4c22-a9a0-f1b4103700de",
                            name: "Angel Face",
                            description: "Gin (30) Apricot Brandy (30) Calvados (30)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.eu-central-1.amazonaws.com/1579258209.366-coctail_2.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "c0e06342-8cbe-443c-b60e-d2e15414e0bb",
                                    ingredientId: "a88def36-2277-4c3f-9bf6-d197a93963a7",
                                    name: "Calvados",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "c15c3883-084e-4978-9c42-3d218bba4b6e",
                                    ingredientId: "bb4d1bba-d0cd-4ecc-92a1-f5c13e08c9ce",
                                    name: "Apricot Brandy",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "cb790c57-cc5c-40e7-b546-7baecfc3932a",
                                    ingredientId: "e8eb4250-777f-4cf1-ae66-efd824ac80e1",
                                    name: "Gin",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        },
                        {
                            id: "70e990a6-110d-4579-b8da-3eb8fa8fe1e3",
                            drinkId: "ce9034c3-80a9-4668-b006-489a6051cdfa",
                            name: "Between The Sheets",
                            description: "Gin (45) Maraschino (15) Lime (15)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.eu-central-1.amazonaws.com/1579258766.849-coctail_2.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "0e632dca-fdb3-485d-a84a-4baa717e8b03",
                                    ingredientId: "b2070d4f-c52f-4763-8f9e-e02ffd751f07",
                                    name: "Maraschino",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "8133deff-b063-4cfb-8ef3-84e5a905c73f",
                                    ingredientId: "da512df3-2872-48be-839c-7084cca02643",
                                    name: "Gin",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "f1ef9306-7314-42a5-ac71-c9536c2b0caf",
                                    ingredientId: "d2f2958c-b89b-457b-970f-ed28f3b30031",
                                    name: "Lime",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        },
                        {
                            id: "9b0fac5c-b1c5-4276-8b68-ad10765ca976",
                            drinkId: "1bf421ee-c2c5-4259-aeca-651c1e6839e5",
                            name: "Bacardi",
                            description: "Gin (45) Maraschino (15) Lime (15)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.eu-central-1.amazonaws.com/1579258679.385-2017_4.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "2106190a-aa8c-4c9c-a313-70ebb18a022e",
                                    ingredientId: "27700be4-7bfe-4cba-be2e-ac8e6174e830",
                                    name: "Maraschino",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "7f2d8323-14ce-4e5d-aaa5-521cc9248fe3",
                                    ingredientId: "477847ec-4fbf-4a00-a0a8-b8c046dcd870",
                                    name: "Gin",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "dc342ab4-d66c-43a3-9574-6ae4cfd63f6f",
                                    ingredientId: "8a92a7bc-14ea-4aa6-acff-4d397b1b5fec",
                                    name: "Lime",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        },
                        {
                            id: "ac722e41-60be-430b-a99a-44e6e2b525d0",
                            drinkId: "9fbbffcf-3bc6-4742-aefa-0b4de1e730bc",
                            name: "Avilion",
                            description: "Gin (45) Maraschino (15) Lime (15)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.eu-central-1.amazonaws.com/1579258319.361-coctaiol_3.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "6f72a366-8400-43bc-bdd7-fe4f1fbddfcf",
                                    ingredientId: "dbc4c873-5144-45b3-9ba6-ecf9cba418ba",
                                    name: "Gin",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "8298b985-0fa8-4d52-bdfa-ec079d71e9bc",
                                    ingredientId: "7343dbc2-f79a-4f7b-b39d-4e9d4cb4f4c1",
                                    name: "Maraschino",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "dedfe40d-fb0d-4eee-9841-58af47e8df7c",
                                    ingredientId: "940d4989-9d14-420f-bd56-d4c8d78c3093",
                                    name: "Lime",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        },
                        {
                            id: "d6fc805f-a7bd-4a39-83d1-c5b0788d9f59",
                            drinkId: "e18a3be0-cf49-4df2-a388-038411842c66",
                            name: "Bramble",
                            description: "Gin (45) Maraschino (15) Lime (15)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.eu-central-1.amazonaws.com/1579258817.702-2017_4.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "aaf43691-1511-400a-8747-7285ce1dea10",
                                    ingredientId: "17fb0353-3c38-4755-9d34-7e7778fa83a6",
                                    name: "Maraschino",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "b27d9231-d50c-4091-913e-648e55415728",
                                    ingredientId: "7576aaf3-497e-4de2-9b42-c65cb7223433",
                                    name: "Lime",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "cc419783-26a6-4705-afae-5179fcaa8a4e",
                                    ingredientId: "6f9fc4c8-fb36-448a-9f1f-3c3a01ee9beb",
                                    name: "Gin",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        },
                        {
                            id: "f5b851d7-5d56-4101-a80e-935b865f7952",
                            drinkId: "9ed7e110-cb62-49e1-a0e8-84de2e1c0b04",
                            name: " Americano",
                            description: "Bitter Campari (30) Martini Red (30) Soda (30)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.amazonaws.com/1579257902.462-coctail-one.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "16d7f5cc-c9bf-4b1f-a8c3-87a17c8b1cd2",
                                    ingredientId: "6927d640-14f7-445a-9079-b7315897ac4d",
                                    name: "Bitter Campari",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "4d6efe0d-01cc-4038-9bce-030cf8833455",
                                    ingredientId: "964d480a-03f1-463e-83e2-e18f48032a44",
                                    name: "Soda",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "cbee74aa-e193-43a2-8dee-dc90da43fa4e",
                                    ingredientId: "ade33b0d-4177-41b4-bc00-decf13dc6d73",
                                    name: "Martini Red",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        },
                        {
                            id: "f664fd1d-4095-4a35-bed9-864273cd9127",
                            drinkId: "4d8258cf-a005-4d10-82cf-7e3d3b98a5a2",
                            name: "Banana Daiquiri",
                            description: "Gin (45) Maraschino (15) Lime (15)",
                            price: "4.0 eu",
                            image: "https://menu-images-bucket.s3.eu-central-1.amazonaws.com/1579258707.961-2017_16.jpg",
                            imageSmall: null,
                            type: "simple",
                            ingredients: [
                                {
                                    id: "47b2f61c-71a2-4fd6-829c-81a94119bc43",
                                    ingredientId: "abc7dff1-c56f-4d47-9ebe-a0b6e9f50383",
                                    name: "Gin",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "6e18631b-e8e0-46a7-8760-9765bd8975f9",
                                    ingredientId: "efddf2c3-bf28-4930-be27-7c6e414b9ed9",
                                    name: "Lime",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                },
                                {
                                    id: "83a6c7a7-6876-45ac-a611-7fde5fc3fc88",
                                    ingredientId: "15c45d5f-3a3c-42ab-b10c-8d51d5f0f47d",
                                    name: "Maraschino",
                                    amount: 2,
                                    removable: 1,
                                    included: 1
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    }
    ]
  }
}



export default { meals, categories, languages, uiConf, menus, drinks,restaurantConf };
