import React, { useState, useEffect, useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Grid } from "@material-ui/core";

const SocialMediaLinks = ({ visible, clickFab }) => {
  const [showed, setShowed] = useState(false);
  const context = useContext(GlobalContext);
  useEffect(() => {
    setShowed(visible);
  }, [visible]);

  let styleLinks = {
    fontSize: "11px",
    backgroundColor: "transparent",
    color: "#161616",
    // padding: 2px 6px 2px 6px;
    borderTop: "0px solid #161616",
    borderRight: "0px solid #161616",
    bordeBbottom: "0px solid #161616",
    borderLeft: "0px solid #161616",
    borderRadius: 3125,
    height: 48,
  };
  // this links comes from socialMedia from global state(context)
  const socialMediaLinks = [
    {
      icon: (
        <img
          height="50px"
          // style={styleLinks}
          src={require("../../assets/illustrations/facebook.svg")}
        />
      ),
      link: context.configuration?.restaurantDetails?.socialMedia?.facebook
        ?.url,
      isFacebook:
        context.configuration?.restaurantDetails?.socialMedia?.facebook
          ?.enabled,
    },
    {
      icon: (
        <img
          height="50px"
          // style={styleLinks}
          src={require("../../assets/illustrations/instagram.svg")}
        />
      ),
      link: context.configuration?.restaurantDetails?.socialMedia?.instagram
        ?.url,
      isInstagram:
        context.configuration?.restaurantDetails?.socialMedia?.instagram
          ?.enabled,
    },
    {
      icon: (
        <img
          height="50px"
          // style={styleLinks}
          src={require("../../assets/illustrations/web.svg")}
        />
      ),
      link: context.configuration?.restaurantDetails?.socialMedia?.web?.url,
      isWebSite:
        context.configuration?.restaurantDetails?.socialMedia?.web?.enabled,
    },
    {
      icon: (
        <img
          height="50px"
          // style={styleLinks}
          src={require("../../assets/illustrations/contact-phone.png")}
        />
      ),
      phoneNumber:
        context.configuration?.restaurantDetails?.socialMedia?.contactPhone
          ?.phoneNumber,
      isPhone:
        context.configuration?.restaurantDetails?.socialMedia?.contactPhone
          ?.enabled,
    },
  ];

  let closeFabOnClick = () => {
    clickFab();
  };

  let hrefLinks = (e) => {
    if (e.isFacebook || e.isInstagram) {
      if (e.link.includes("https://") && !e.isPhone) {
        return e.link;
      } else if (!e.link.includes("https://") && !e.isPhone) {
        return "https://" + e.link;
      }
    }
    if (e.isWebSite) {
      if (e.link.includes("https://") || e.link.includes("http://"))
        return e.link;
      return "https://" + e.link;
    }
    if (e.isPhone) {
      return `tel: ${e.phoneNumber}`;
    }
  };

  return (
    showed && (
      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          flexDirection: "row",
          padding: 0,
          WebkitAlignItems: "flex-end",
          WebkitJustifyContent: "flex-end",
          WebkitFlexDirection: "row",
          flexGrow: 1,
          WebkitFlexGrow: 1,
          position: "fixed",
          bottom: "31.9pt",
          left: "66pt",
          zIndex: 9999,
        }}
      >
        {socialMediaLinks.map((e, index) => {
          return e.isFacebook || e.isInstagram || e.isWebSite || e.isPhone ? (
            <Grid
              onClick={() => closeFabOnClick()}
              style={{
                padding: 5,
              }}
              key={index}
            >
              <a target="_blank" href={hrefLinks(e)} key={index}>
                {e.icon}
              </a>
            </Grid>
          ) : null;
        })}
      </Grid>
    )
  );
};

export default SocialMediaLinks;
