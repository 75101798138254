import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import MealCard from "./MealCard";
import GlobalContext from "../../context/GlobalContext";
import bgImg from "../../assets/micasabackground.jpg";

const Meals = () => {
  const context = useContext(GlobalContext);
  const params = useParams();

  let category = null;

  if (context.menu) {
    category = context.menu[params.type].categories.find(
      (cat) => cat.categoryId === params.category
    );
  }

  const renderItems = () => {
    console.log("renderItems");
    return category.items.map((m) => <MealCard key={m.id} meal={m} />);
  };

  const getBackgroundStyle = () => {
    let style = { paddingTop: 60, filter: context.blur };
    if (window.location.href.includes("cafe-croatia")) {
      style.backgroundImage = `url("https://images.unsplash.com/photo-1554866585-cd94860890b7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8Y29jYSUyMGNvbGF8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&w=1000&q=80")`;
      style.backgroundSize = "cover";
    }
    if (window.location.href.includes("mi-casa")) {
      style.position = "relative";
      // style.zIndex = 17;
    }

    if (window.location.href.includes("mi-casa")) {
      style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/micasabackground.jpeg")`;
      style.backgroundSize = "100%";
      style.backgroundPosition = "center";
      style.backgroundRepeat = "no-repeat";
      style.backgroundAttachment = "fixed";
    }

    if (window.location.href.includes("oriental")) {
      style.backgroundImage = `url("https://preconfigured-background-images.s3.eu-central-1.amazonaws.com/OrientalPozadina.png")`;
      style.backgroundSize = "100%";
      style.backgroundPosition = "center";
      style.backgroundRepeat = "no-repeat";
      style.backgroundAttachment = "fixed";
    }

    if (
      window.location.href.includes("lokal45") &&
      localStorage.getItem("redBullDemo")
    ) {
      style = JSON.parse(localStorage.getItem("redBullDemo"));
      style.paddingTop = 60;
    }
    return style;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    //console.log("brane")
    //window.scrollTo({top: 0, behavior: 'smooth'});
  }, [category]);

  return (
    <>
      <div className="scroll-content" style={getBackgroundStyle()}>
        {category && renderItems()}
      </div>
    </>
  );
};

export default Meals;
