const setUpWebSocket = async () => {
  console.log("SET UP WEB SOCKET");
  var W3CWebSocket = require("websocket").w3cwebsocket;

  var client = new W3CWebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}`);

  return client;
};

const makeCall = async (action) => {
  const client = await setUpWebSocket();
  client.onerror = function (err) {
    console.log("Connection Error", err);
  };

  client.onopen = function () {
    console.log("WebSocket Client Connected");
    let table = window.location.search || localStorage.getItem("call");
    table = table.replace("?", "");
    table = table.replace("=", " ");
    table = table.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    client.send(
      JSON.stringify({
        action: "sendmessage",
        data: `${table} ${action}`,
      })
    );
    console.log("Vi blagodarime, vaseto baranje bese procesirano");
  };
  client.onclose = function () {
    client.close();
    console.log("echo-protocol Client Closed");
  };

  client.onmessage = function (e) {
    console.log("EVENT", e);
  };
};

export {
    setUpWebSocket,
    makeCall
}
